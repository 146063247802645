.main-content {
  display: flex;
  flex-direction: column;
}

.site-content {
  z-index: 1;
}

.centered-text {
  position: absolute;
  width: 70%;
  padding: 20px;
  color: white;
  bottom: 30%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 70px;
}

.full-width-block {
  /* border: 5px solid red;  */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
}

.side-container h1 {
  font-weight: bold;
}

.form-group {
  width: 100%; /* Set default width to 100% */
  /* margin-bottom: 4%; */
}

.form-group label {
  font-weight: bold;
  margin-bottom: 2.5%;
}

@media (min-width: 769px) {

  .home-container {
    position: relative; /* Add this to establish a positioning context */
    /* z-index: -1; */
    top: -10vh;
    height: 90vh;
    width: 100%;
  }

  .corner-text {
    position: absolute;
    z-index: 1;
    right: 10%; /* Adjust as needed */
    bottom: 10%; /* Adjust as needed */
    color: white;
    font-size: 20px; /* Adjust as needed */
    text-decoration: none;
    display: inline-block; /* Displays the link as an inline element */
  }

  .corner-text:hover {
    text-decoration: underline; /* Adds underline on hover */
    cursor: pointer;
  }
  

  .video-foreground img {
    display: none;
  }

  .video-foreground video {
    height: 100%;
    width: 100%;
  }

  .home-form-block {
    display: flex;          
    justify-content: center;   
    align-items: center;
    height: 75vh;
  }

  .home-form-block .side-container{
    width: calc(45%);
    margin: 0 20px;
    padding: 5%;
  }

  .home-form-container {
    /* height: 100vh; */
    width: 25%;
  }

  .home-form-container .form-group {
    margin-bottom: 2%;
  }

  .home-form-container .form-group label {
    font-weight: bold;
    margin-bottom: 1%;
  }

  .full-width-block {
    z-index: 2;
    margin-top:1vh;
    height: 30vh;
  }

  .full-width-block p {
    margin: 0;
    font-size: 5vw;
    color: white;
  }

  .full-width-block span {
    margin: 0;
    font-size: 2vw; /* Adjust based on your preference */
    color: white;
  }

  .text-wrapper {
    display: flex;
    justify-content: space-around;
    width: 90%;
  }
}

/* For mobile phones: */
@media (max-width: 768px) {
  .home-container {
    position: relative; /* Add this to establish a positioning context */
    z-index: -1;
    top: -10vh;
    width: 100%;
    overflow: hidden;
  }
  

  .centered-text {
    font-size: 35px; /* Reduced font size for smaller screen */
    width: 90%; /* Increased width percentage for smaller screen */
  }

  .corner-text {
    position: absolute;
    z-index: 1;
    right: 10%; /* Adjust as needed */
    bottom: 10%; /* Adjust as needed */
    color: white;
    font-size: 20px; /* Adjust as needed */
    display: inline-block; /* Displays the link as an inline element */
  }

  .corner-text {
    font-size: 16px; /* Reduced font size for smaller screen */
  }

  .footer-big-text {
    font-size: 20px; /* Reduced font size for smaller screen */
  }

  .footer-text {
    font-size: 10px; /* Reduced font size for smaller screen */
  }

  .video-foreground video {
    display: none;
  }

  .video-foreground img {
    width: 100%;
    /* height: 100%; */
    object-fit: cover; /* Add this line */
    object-position: center; /* Add this line */
    
  }

  .home-form-block {
    padding: 15px;
  }

  .full-width-block {
    margin-top: -10vh;
    height: auto;
    padding: 20px 0;
  }

  .text-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
  
  .text-container {
    width: 33%;
    margin-bottom: 20px;
  }

  .text-container:nth-child(4),
  .text-container:nth-child(5) {
    width: 50%;
  }

  .full-width-block p {
    margin: 0;
    font-size: 9vw;
    color: white;
  }
  
  .full-width-block span {
    margin: 0;
    font-size: 3.9vw;
    color: white;
  }

  .text-wrapper {
    display: flex;
    justify-content: space-around;
    width: 90%;
  }

  .side-container h1 {
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .side-container {
    margin-bottom: 3em;
  }

  .centered-text {
    top: 25%;
  }
  
  .corner-text {
    bottom: 5%;
  }
}
