.contact-container {
  display: flex;
  height: 100vh;
}

.company-info {
  flex: 1;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

.contact-info {
  flex: 1;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-box {
  border: 2px solid #000;
  padding: 20px;
  width: 70%;
  text-align: center;
}

/* Media Query for screens less than or equal to 768 pixels */
@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
  }

  .company-info, .contact-info {
    flex: unset;
    width: 100%;
  }

  .contact-box {
    width: 80%;
    margin: auto;
  }
}
