.portfolio body {
  background-color: white;
}

.portfolio {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
  }
  
  .portfolio-item {
    flex-basis: calc(20% - 10px);
    margin-bottom: 25px;
    margin-right: 35px;
    background-color: #f5f5f5;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    border-radius: 10px; /* Add this line to your CSS */
  }

  .portfolio-item:hover {
    transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Add box-shadow to the transition */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Increase box-shadow on hover */
    cursor: pointer;
  }
  
  
  .portfolio-item img {
    width: 100%;
    height: auto;
    border-radius: 10px; /* Make the corners of the image rounded */
  }

  .item-content {
    padding-left: 0.75em;
  }

  .flex-container {
    display: flex;
    /* align-items: center; */
    /* justify-content: space-around; Adjust this based on how you want the spacing */
  }

  .flex-container .bullet {
      margin: 0 10px; /* Adds space around the bullet. Adjust as needed. */
  }

  /* Optional: Style your spans as needed */
  .flex-container span {
      display: block;
  }
  
  .portfolio-header {
    text-align: center;
    /* margin-top: 3%; */
  }
  
  .title {
    font-size: 2em; /* Adjust as needed */
    margin-bottom: 0.5em; /* Adjust as needed */
  }
  
  .subtitle {
    font-size: 1.2em; /* Adjust as needed */
    margin-bottom: 1em; /* Adjust as needed */
  }

  .portfolio-item .city {
    font-weight: bold;
    margin: 10px 0;
}

/* Optional styling for description and another text */
.portfolio-item .description,
.portfolio-item .another-text {
    margin-bottom: 10px; /* You can adjust this value based on your needs */
}

@media (max-width: 768px) {
  .portfolio-item {
    flex-basis: calc(75% - 10px);
    margin-right: 10px;
    margin-bottom: 25px;
  }

  .portfolio-item img {
    height: auto;
  }

  .portfolio-header {
    margin-top: 3%;
  }

  .title {
    font-size: 1.5em;
  }

  .subtitle {
    font-size: 1em;
  }

  .portfolio {
    padding: 10px;
    padding-top: 20px;
  }

  .portfolio-item .city {
    font-weight: bold;
    margin: 5px 0;
  }

  /* New styles for mobile layout */
  .flex-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .flex-container .bullet {
    display: none;
  }

  .flex-container .another-text,
  .flex-container .description {
    margin-bottom: 2.5px;
  }
}
  